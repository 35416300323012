<template>
  <div
    class="fixed min-h-screen w-full flex justify-center items-center"
    style="background: rgba(0, 0, 0, 0.3); z-index: 100"
  >
    <div
      class="w-1/2 p-4 bg-white rounded-md shadow-md"
      style="max-height: 90%"
    >
      <div class="w-full flex justify-between mb-2">
        <div class="w-full font-semibold">Media</div>
        <div class="w-2">
          <i class="fa fa-close cursor-pointer" @click="closeModal"></i>
        </div>
      </div>
      <hr />
      <div class="w-full mt-4 p-6">
        <div>
          <video
            v-if="evidence.type === 'video'"
            :src="evidence.file_url"
            controls
            class="w-full"
            style="height: 240px"
          >
            Your browser does not support the video tag.
          </video>
          <img
            v-else
            :src="evidence.file_url"
            alt="Gambar"
            style="max-width: 100%; margin: auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
// eslint-disable-next-line no-unused-vars
const evidence = computed(() => store.state.evidence.selectedEvidence);

// eslint-disable-next-line no-unused-vars
const closeModal = () => store.dispatch("modal/setModalImage", false);
</script>
