<template>
  <div class="">
    <main>
      <section class="relative w-full h-full py-40 min-h-screen login-centerly">
        <!-- :style="`background-image: url('${registerBg2}');`" -->
        <div
          class="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
          ></div>
        <router-view />
        <!-- <footer-small absolute /> -->
      </section>
    </main>
  </div>
</template>
<script>
// import FooterSmall from "@/components/Footers/FooterSmall.vue";

// import registerBg2 from "@/assets/img/register_bg_2.png";

export default {
  data() {
    return {
      // registerBg2,
    };
  },
  components: {
    // FooterSmall,
  },
};
</script>
