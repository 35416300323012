<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700 uppercase">
            List Photo & Video
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              File
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Tanggal
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Jam
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Lokasi
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Kegiatan
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="evidence in evidences" :key="evidence.id">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 relative"
            >
              <div
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: transparent;
                  width: 100%;
                  height: 100%;
                  z-index: 99;
                  cursor: pointer;
                "
                v-if="evidence.type === 'video'"
                @click="showMedia(evidence)"
              ></div>
              <video
                v-if="evidence.type === 'video'"
                :src="evidence.file_url"
                controls
                class="w-full"
                style="max-width: 240px"
              >
                Your browser does not support the video tag.
              </video>
              <img
                v-else
                :src="evidence.file_url"
                alt="Gambar"
                style="max-width: 100px; max-height: 100px"
                class="cursor-pointer"
                @click="showMedia(evidence)"
              />
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ dateFormat(evidence.created_at) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ timeFormat(evidence.created_at) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 font-semibold"
            >
              {{ evidence.location_name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ mapActivityType(evidence.activity_name) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination Component -->
    <div class="flex justify-between items-center p-4">
      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === 1"
        @click="changePage(page - 1)"
      >
        Previous
      </button>

      <span class="text-sm text-gray-700">
        Page {{ page }} of {{ totalPages }}
      </span>

      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === totalPages"
        @click="changePage(page + 1)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "../../utils/mapActivityType";
// eslint-disable-next-line no-unused-vars
import { dateFormat } from "../../utils/dateFormat";
// eslint-disable-next-line no-unused-vars
import { timeFormat } from "../../utils/timeFormat";
// eslint-disable-next-line no-unused-vars
const totalPages = computed(() => store.state.evidence.totalPages);
const page = ref(1);

const store = useStore();
// eslint-disable-next-line no-unused-vars
const evidences = computed(() => store.state.evidence.evidences);
const activityId = computed(() => store.state.filter.activityId);
const itemId = computed(() => store.state.filter.itemId);

// eslint-disable-next-line no-unused-vars
const showMedia = (evidence) => {
  store.dispatch("evidence/setSelectedEvidence", evidence);
  store.dispatch("modal/setModalImage", true);
};

// eslint-disable-next-line no-unused-vars
const changePage = (val) => {
  page.value = val;
  store.dispatch("evidence/getData", {
    perPage: 10,
    page: val,
    activityId: activityId.value,
    itemId: itemId.value,
  });
};

onMounted(() => {
  store.dispatch("evidence/clearEvidences");
});
</script>
