<template>
  <div class="flex mt-4">
    <div class="w-full px-4">
      <card-presences />
    </div>
  </div>
</template>
<script>
import CardPresences from "@/components/Dashboard/CardPresences.vue";

export default {
  components: {
    CardPresences
  },
};
</script>
