<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 xl:mb-0 px-4">
        <card-activity-detail />
      </div>
    </div>
  </div>
</template>
<script>
import CardActivityDetail from "@/components/Dashboard/CardActivityDetail.vue";
export default {
  name: "dashboard-page",
  components: {
    CardActivityDetail,
  },
};
</script>
