<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-sm text-blueGray-700 uppercase">
            Laporan Kegiatan
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="seeAllActivity"
            v-if="!isShowActivity"
          >
            See all
          </button>
          <button
            class="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="seeLessActivity"
            v-if="isShowActivity"
          >
            Back
          </button>
          <button
            class="bg-gray-200 text-xs font-bold px-3 py-1 rounded outline-none focus:outline-none"
            type="button"
            @click="refresh"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Lokasi
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Pengawas
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Korlap
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Kegiatan
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in reports" :key="report.id">
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center"
            >
              {{ report.location }}
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              <div class="flex items-center justify-center">
                <i
                  class="fas fa-circle text-emerald-500 mr-2"
                  v-if="report.is_supervisor_presence"
                ></i>
                <i class="fas fa-circle text-red-500 mr-2" v-else></i>
                <!-- {{ report.supervisor }} -->
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 flex justify-center"
            >
              <i
                class="fas fa-circle text-emerald-500 mr-2"
                v-if="report.is_leader_presence"
              ></i>
              <i class="fas fa-circle text-red-500 mr-2" v-else></i>
              <!-- {{ report.leader }} -->
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{
                report.current_state
                  ? mapActivityType(report.current_state.type)
                  : ""
              }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{
                report.current_state
                  ? mapActivityStatus(report.current_state.status)
                  : ""
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "@/utils/mapActivityType";
// eslint-disable-next-line no-unused-vars
import { mapActivityStatus } from "@/utils/mapActivityStatus";

const store = useStore();

// eslint-disable-next-line no-unused-vars
const reports = computed(() => store.state.activity.reports);
// eslint-disable-next-line no-unused-vars
const isShowActivity = computed(() => store.state.modal.isShowActivity);

// eslint-disable-next-line no-unused-vars
const refresh = async () => await store.dispatch("activity/getProgressReports");

// eslint-disable-next-line no-unused-vars
const seeAllActivity = async () => {
  store.dispatch("modal/setModalActivity", true);
  store.dispatch("modal/setModalPercentage", false);
  await store.dispatch("activity/getProgressReports", "*");
};

// eslint-disable-next-line no-unused-vars
const seeLessActivity = async () => {
  store.dispatch("modal/setModalActivity", false);
  store.dispatch("modal/setModalPercentage", false);
  await store.dispatch("activity/getProgressReports", "5");
};

onMounted(async () => await store.dispatch("activity/getProgressReports"));
</script>
