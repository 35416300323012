<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700 uppercase">
            <i class="fa fa-arrow-left cursor-pointer" @click="goBack"></i>
            Form Kegiatan
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full px-4 overflow-x-auto">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Jenis Kegiatan
          </label>
          <select
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            v-model="type"
          >
            <option
              value="receive"
              v-if="lastTypeIndex == 0 || props.activity != null"
            >
              Penerimaan Barang
            </option>
            <option
              value="installation"
              v-if="lastTypeIndex == 1 || props.activity != null"
            >
              Installasi
            </option>
            <option
              value="test"
              v-if="lastTypeIndex == 2 || props.activity != null"
            >
              Uji Fungsi
            </option>
            <option
              value="daily use"
              v-if="
                lastTypeIndex == 3 ||
                lastTypeIndex == 4 ||
                props.activity != null
              "
            >
              Pelaksanaan Harian
            </option>
            <option
              value="take-down"
              v-if="lastTypeIndex == 4 || props.activity != null"
            >
              Pembongkaran
            </option>
            <option value="rejection">
              Kendala
            </option>
          </select>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Keterangan
          </label>
          <input
            type="text"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Keterangan"
            v-model="name"
          />
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Tanggal
          </label>
          <input
            type="date"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Tanggal Kegiatan"
            v-model="date"
          />
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Jumlah Unit / Peserta
          </label>
          <select
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            v-model="category"
          >
            <option value="1">100 Unit - 100 Peserta</option>
            <option value="2">200 Unit - 200 Peserta</option>
            <option value="3">300 Unit - 300 Peserta</option>
            <option value="4">400 Unit - 400 Peserta</option>
            <option value="5">500 Unit - 500 Peserta</option>
          </select>
        </div>
      </div>
      <div class="px-4">
        <button
          class="px-4 py-2 bg-blueGray-600 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
          @click="showModal"
        >
          <i class="fa fa-plus mr-2"></i> Tambah Kategori/Barang
        </button>
      </div>
      <div class="px-4 my-4">
        <table class="w-full border-collapse border border-slate-500">
          <thead>
            <tr class="bg-indigo-500 text-white">
              <th>No.</th>
              <th>Nama</th>
              <th>Jumlah</th>
              <th>Satuan</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="items.length < 1">
              <td colspan="5" class="text-center text-sm py-2">
                Belum ada Kategori/Barang yang dipilih
              </td>
            </tr>
            <tr v-for="(item, index) in items" :key="index" class="border">
              <td align="center" class="text-sm">{{ index + 1 }}</td>
              <td align="left" class="text-sm">
                {{ item.name }}
              </td>
              <td align="center" class="text-sm">{{ item.qty }}</td>
              <td align="center" class="text-sm">{{ item.unit }}</td>
              <td align="center" class="text-sm">
                <button
                  class="text-red-500 hover:shadow-lg text-white font-semibold text-sm rounded"
                  @click="removeItem(item)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="p-4">
      <button
        class="px-4 py-2 bg-emerald-500 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
        @click="submit"
      >
        <i class="fa fa-check mr-2"></i> Simpan
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";

const $toast = useToast();

const store = useStore();
const router = useRouter();

const props = defineProps({
  activity: {
    type: Object,
    default: null,
  },
});

const items = computed(() => store.state.activity.items);
const selectedItems = computed({
  get: () => store.state.activity.items,
  set: (val) => store.dispatch("activity/setItems", val),
});
// eslint-disable-next-line no-unused-vars
const lastTypeIndex = computed(() => store.state.activity.lastTypeIndex);

const name = ref("");
const type = ref("");
const date = ref("");
const category = ref("");

watch(
  () => category.value,
  (val) => store.dispatch("item/setCategory", val)
);

// Show modal function
// eslint-disable-next-line no-unused-vars
const showModal = () => store.dispatch("modal/setModalItems", true);

// eslint-disable-next-line no-unused-vars
const goBack = () => router.go(-1);

// Remove an item from the list
// eslint-disable-next-line no-unused-vars
const removeItem = (item) => {
  const updatedItems = selectedItems.value.filter((i) => i.id !== item.id);
  store.dispatch("activity/setItems", updatedItems);
};

const typeValidation = () => {
  if (type.value == "installation" && lastTypeIndex.value < 1) {
    $toast.error("Harap buat kegiatan Penerimaan Barang terlebih dahulu");
    return false;
  }
  if (type.value == "test" && lastTypeIndex.value < 2) {
    $toast.error("Harap buat kegiatan Installasi terlebih dahulu");
    return false;
  }
  if (type.value == "daily use" && lastTypeIndex.value < 3) {
    $toast.error("Harap buat kegiatan Uji Fungsi terlebih dahulu");
    return false;
  }
  if (type.value == "take-down" && lastTypeIndex.value < 4) {
    $toast.error("Harap buat kegiatan Pelaksanaan Harian terlebih dahulu");
    return false;
  }
  return true;
};

// Submit the form
// eslint-disable-next-line no-unused-vars
const submit = async () => {
  await store.dispatch("modal/setLoading", true);
  const payload = {
    name: name.value,
    type: type.value,
    date: date.value,
    category: category.value,
    details: items.value,
  };

  const check = typeValidation();
  if (!check) return;

  if (props.activity) {
    payload.id = props.activity.id;
    await store.dispatch("activity/update", payload);
  } else await store.dispatch("activity/store", payload);

  await store.dispatch("modal/setLoading", false);
  router.push(`/home/activities`);
};

// Set form values based on the provided activity
const setFormValues = () => {
  if (props.activity) {
    const activity = props.activity;

    name.value = activity.name || "";
    type.value = activity.type || "";
    category.value = activity.category || "";
    date.value = activity.date || "";
    selectedItems.value = activity.details || [];
  } else {
    selectedItems.value = [];
  }
};

// Watch for changes in the activity prop and update the form values accordingly
watch(
  () => props.activity,
  () => setFormValues(),
  { immediate: true } // Run immediately on component mount
);

// Initial setup on mount
onMounted(() => {
  setFormValues();
});
</script>
