<template>
  <div class="flex mt-4">
    <div class="w-full px-4">
      <card-locations />
    </div>
  </div>
</template>
<script>
import CardLocations from "@/components/Dashboard/CardLocations.vue";

export default {
  components: {
    CardLocations
  },
};
</script>
