import checkToken from "../../utils/checkToken";
import { API_URL } from "../constant";

const state = {
  token: localStorage.getItem("token") || "",
  presences: [],
  selectedPresence: null,
  totalPages: 1,
};

const mutations = {
  SET_PRESENCES(state, presences) {
    state.presences = presences;
    // state.selectedPresence = null;
  },
  SET_SELECTED_PRESENCE(state, selectedPresence) {
    state.selectedPresence = selectedPresence;
  },
  SET_TOTAL_PAGE(state, totalPages) {
    state.totalPages = totalPages;
  },
};

const actions = {
  async getData({ commit }, { perPage = 10, page = 1, areaId = null }) {
    try {
      // Create a URL object
      const url = new URL(`${API_URL}/presences`);

      // Use URLSearchParams to append query parameters
      const params = new URLSearchParams(
        areaId != null ? { perPage, page, area_id: areaId } : { perPage, page }
      );

      // Attach the query parameters to the URL
      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        commit("SET_PRESENCES", data.data);
        commit("SET_TOTAL_PAGE", data.meta.last_page);
      } else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      checkToken(error);
      throw error;
    }
  },
  async store({ commit, state }, payload) {
    try {
      const response = await fetch(`${API_URL}/presences`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
        body: payload, // This will be FormData, handled correctly without setting Content-Type
      });

      const data = await response.json();

      if (response.ok) commit("SET_SELECTED_PRESENCE", data.data);
      else throw new Error("Store data failed");
    } catch (error) {
      console.error("Error storing data:", error);
      throw error;
    }
  },
  async downloadExcel({ state }, areaId = null) {
    try {
      // Create a URL object
      const url = new URL(`${API_URL}/presences`);

      // Use URLSearchParams to append the area_id parameter if provided
      const params = new URLSearchParams(
        areaId != null ? { area_id: areaId, export: "excel" } : { export: "excel" }
      );

      // Attach the query parameters to the URL (area_id in this case)
      url.search = params.toString();

      // Make the request to download the file
      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to download Excel file");

      // Extract the filename from the Content-Disposition header
      const disposition = response.headers.get("Content-Disposition");
      const filename = disposition
        ? disposition.match(/filename="(.+)"/)[1]
        : "Laporan Absensi.xlsx";

      // Create a Blob from the response
      const blob = await response.blob();

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // Append link to the body and trigger click
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
      throw error;
    }
  },
  setSelectedPresence({ commit }, presence) {
    commit("SET_SELECTED_PRESENCE", presence);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
