<template>
  <div class="flex mt-4">
    <div class="w-full px-4">
      <card-handovers />
    </div>
  </div>
</template>
<script>
import CardHandovers from "@/components/Dashboard/CardHandovers.vue";

export default {
  components: {
    CardHandovers
  },
};
</script>
