import checkToken from "../../utils/checkToken";
import { API_URL } from "../constant";

const state = {
  token: localStorage.getItem("token") || "",
  activities: [],
  selectedActivity: null,
  totalPages: 1,
  items: [],
  selectedItem: null,
  lastTypeIndex: 0,
  summaries: {
    total_location: 0,
    total_supervisor: 0,
    total_leader: 0,
    percentage: 0,
  },
  reports: [],
  selected_report: {
    total_activities: 0,
    total_completed: 0,
    current_state: { status: "" },
  },
};

const mutations = {
  SET_ACTIVITIES(state, activities) {
    state.activities = activities;
    // state.selectedActivity = null;
  },
  SET_SELECTED_ACTIVITY(state, selectedActivity) {
    state.selectedActivity = selectedActivity;
  },
  SET_TOTAL_PAGE(state, totalPages) {
    state.totalPages = totalPages;
  },
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_SELECTED_ITEM(state, selectedItem) {
    state.selectedItem = selectedItem;
  },
  SET_LAST_TYPE_INDEX(state, lastTypeIndex) {
    state.lastTypeIndex = lastTypeIndex;
  },
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },
  SET_SELECTED_REPORT(state, selected_report) {
    state.selected_report = selected_report;
  },
  SET_SUMMARIES(state, summaries) {
    state.summaries = summaries;
  },
};

const TYPE_TAKE_DOWN = 5;
const TYPE_DAILY_USE = 4;
const TYPE_TEST = 3;
const TYPE_INSTALLATION = 2;
const TYPE_DEFAULT = 1;

const getLatestType = (data) => {
  if (data.length < 2) return TYPE_DEFAULT;

  // Check for types in the order of priority
  const typeMapping = {
    "take-down": TYPE_TAKE_DOWN,
    "daily use": TYPE_DAILY_USE,
    test: TYPE_TEST,
    installation: TYPE_INSTALLATION,
  };

  // Iterate through the data to find the latest type
  let latest = TYPE_DEFAULT;
  for (const item of data) {
    if (typeMapping[item.type]) {
      latest = typeMapping[item.type]; // Return the corresponding type value
    }
  }

  // Default case if no matching type is found
  return latest;
};

const actions = {
  async getData({ commit, state }, { perPage = 10, page = 1, areaId = null }) {
    try {
      // Create a URL object
      const url = new URL(`${API_URL}/activities`);

      // Use URLSearchParams to append query parameters
      const params = new URLSearchParams(
        areaId != null ? { perPage, page, area_id: areaId } : { perPage, page }
      );

      // Attach the query parameters to the URL
      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        commit("SET_ACTIVITIES", data.data);
        commit("SET_TOTAL_PAGE", data.meta.last_page);

        if (data.data.length < 1) commit("SET_LAST_TYPE_INDEX", 0);
        else commit("SET_LAST_TYPE_INDEX", getLatestType(data.data));
      } else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      checkToken(error);
      throw error;
    }
  },
  clearActivities({ commit }) {
    commit("SET_ACTIVITIES", []);
    commit("SET_TOTAL_PAGE", 1);
  },
  async getDetailData({ commit }, id) {
    try {
      const response = await fetch(`${API_URL}/activities/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
      });

      const data = await response.json();

      if (response.ok) commit("SET_SELECTED_ACTIVITY", data.data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
  async store({ commit }, payload) {
    try {
      const response = await fetch(`${API_URL}/activities`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) commit("SET_SELECTED_ACTIVITY", data.data);
      else throw new Error("Store data failed");
    } catch (error) {
      console.error("Error get data:", error);
      checkToken(error);
      throw error;
    }
  },
  async update({ commit }, payload) {
    try {
      const response = await fetch(`${API_URL}/activities/${payload.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) commit("SET_SELECTED_ACTIVITY", data.data);
      else throw new Error("Store data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
  async approve({ commit }, payload) {
    try {
      const response = await fetch(
        `${API_URL}/activities/${payload.id}/approve`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (response.ok) commit("SET_SELECTED_ACTIVITY", data.data);
      else throw new Error("Store data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
  async reject({ commit }, payload) {
    try {
      const response = await fetch(
        `${API_URL}/activities/${payload.id}/reject`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (response.ok) commit("SET_SELECTED_ACTIVITY", data.data);
      else throw new Error("Store data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
  async uploadEvidence({ state }, { formData }) {
    try {
      const response = await fetch(`${API_URL}/evidences`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (!response.ok) throw new Error("Upload failed");

      // Return success or progress information if needed
      return data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  },
  downloadBAP({ state }, { id, filename, type }) {
    console.log(state);

    // Perform the fetch request to the API endpoint
    fetch(`${API_URL}/activities/${id}/downloadBAP`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      },
    })
      .then((response) => {
        console.log(response.headers); // Log the response headers to check Content-Disposition
        if (!response.ok) {
          throw new Error(`Error fetching file: ${response.statusText}`);
        }
        return response.blob(); // Convert the response into a Blob (binary large object)
      })
      .then((blob) => {
        // Extract the file extension from the filename
        const name = filename.split("/").slice(-1)[0];
        const extension = name.split(".").slice(-1)[0];
        const downloadFilename = `BA_${type}.${extension}`;

        // For Microsoft browsers (IE, Edge)
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // Use msSaveOrOpenBlob for compatibility in IE/Edge
          window.navigator.msSaveOrOpenBlob(blob, downloadFilename);
        } else {
          // For standard browsers, create a download link
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = downloadFilename; // Specify the download filename
          document.body.appendChild(link);
          link.click(); // Trigger the download
          link.remove(); // Clean up the element after triggering the download
          window.URL.revokeObjectURL(downloadUrl); // Revoke the object URL to free up memory
        }
      })
      .catch((error) => {
        console.error("Download failed:", error);
        checkToken(error); // Handle token-related errors
      });
  },
  async downloadExcel({ state }, areaId = null) {
    try {
      // Create a URL object
      const url = new URL(`${API_URL}/activities`);

      // Use URLSearchParams to append the area_id parameter if provided
      const params = new URLSearchParams({ area_id: areaId, export: "excel" });

      // Attach the query parameters to the URL (area_id in this case)
      url.search = params.toString();

      // Make the request to download the file
      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to download Excel file");

      // Extract the filename from the Content-Disposition header
      const disposition = response.headers.get("Content-Disposition");
      const filename = disposition
        ? disposition.match(/filename="(.+)"/)[1]
        : "Laporan Kegiatan.xlsx";

      // Create a Blob from the response
      const blob = await response.blob();

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // Append link to the body and trigger click
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
      throw error;
    }
  },
  setItems({ commit }, items) {
    commit(
      "SET_ITEMS",
      items.sort((a, b) => a.order_no - b.order_no)
    );
  },
  async getDetailItem({ commit }, id) {
    try {
      const response = await fetch(`${API_URL}/activity_details/${id}/item`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) commit("SET_SELECTED_ITEM", data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      checkToken(error);
      throw error;
    }
  },
  async getProgressReports({ commit }, limit = "5") {
    try {
      const response = await fetch(
        `${API_URL}/reports/report-progress-all?limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) commit("SET_REPORTS", data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
  async getProgressReport({ commit }, areaId) {
    try {
      const response = await fetch(
        `${API_URL}/reports/report-progress?area_id=${areaId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) commit("SET_SELECTED_REPORT", data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
  async getSummaries({ commit }) {
    try {
      const response = await fetch(`${API_URL}/reports/summaries`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
      });

      const data = await response.json();
      if (response.ok) commit("SET_SUMMARIES", data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
