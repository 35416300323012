export const dateFormat = (dateStr) => {
  // Convert the string to a Date object
  const date = new Date(dateStr);

  // Format the date into "15 September 2024 15:03"
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  return date
    .toLocaleDateString("id-ID", options)
    .replace(",", "");
};
