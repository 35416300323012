<template>
  <div class="flex mt-4">
    <div class="w-full px-4">
      <card-galleries />
    </div>
  </div>
</template>
<script>
import CardGalleries from "@/components/Dashboard/CardGalleries.vue";

export default {
  components: {
    CardGalleries
  },
};
</script>
