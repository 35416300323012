import checkToken from "../../utils/checkToken";
import { API_URL } from "../constant";

const state = {
  token: localStorage.getItem("token") || "",
  evidences: [],
  selectedEvidence: null,
  totalPages: 1,
};

const mutations = {
  SET_EVIDENCES(state, evidences) {
    state.evidences = evidences;
    // state.selectedEvidence = null;
  },
  SET_SELECTED_EVIDENCE(state, selectedEvidence) {
    state.selectedEvidence = selectedEvidence;
  },
  SET_TOTAL_PAGE(state, totalPages) {
    state.totalPages = totalPages;
  },
};

const actions = {
  async getData(
    { commit },
    { perPage = 10, page = 1, activityId, itemId = null }
  ) {
    try {
      console.log('item:', itemId);
      // Create a URL object
      const url = new URL(`${API_URL}/evidences`);

      // Use URLSearchParams to append query parameters
      const paramKeys =
        itemId != null
          ? { perPage, page, activityId, activity_detail_id: itemId }
          : { perPage, page, activityId };
      const params = new URLSearchParams(paramKeys);

      // Attach the query parameters to the URL
      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        commit("SET_EVIDENCES", data.data);
        commit("SET_TOTAL_PAGE", data.meta.last_page);
      } else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      checkToken(error);
      throw error;
    }
  },
  async getDataByDetail({ commit }, activityDetailId) {
    try {
      const response = await fetch(
        `${API_URL}/evidences?activity_detail_id=${activityDetailId}&perPage=100`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) commit("SET_EVIDENCES", data.data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
  async delete({ state }, evidenceId) {
    try {
      const response = await fetch(`${API_URL}/evidences/${evidenceId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
      });

      if (response.ok) {
        // Optionally update the state or refetch data after deletion
        console.log(state.evidences); // Assuming you have a mutation to remove the deleted evidence from the state
      } else {
        throw new Error("Delete evidence failed");
      }
    } catch (error) {
      console.error("Error deleting evidence:", error);
      throw error;
    }
  },
  setSelectedEvidence({ commit }, evidence) {
    commit("SET_SELECTED_EVIDENCE", evidence);
  },
  clearEvidences({ commit }) {
    commit("SET_EVIDENCES", []);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
