<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <div
        class="md:block text-left text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold cursor-pointer"
        @click="goToHome"
      >
        <img :src="logo" width="120" alt="" style="margin: auto" />
      </div>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown />
        </li>
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow"
        ref="target"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/"
              >
                Teman Terbaik
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Form -->
        <!-- <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form> -->

        <!-- Divider -->
        <!-- <hr class="my-4 md:min-w-full" /> -->
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
          Activity Management
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center" v-if="isTopLevel">
            <router-link
              to="/home/dashboard"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="handleNavigate(navigate)"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-tv mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Dashboard
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <router-link
              to="/home/activities"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="handleNavigate(navigate)"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-tools mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                {{ isLeader ? "Manage Kegiatan" : "Laporan Kegiatan" }}
              </a>
            </router-link>
          </li>

          <li class="items-center" v-if="isAdmin || isTopLevel">
            <router-link
              to="/home/locations"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="handleNavigate(navigate)"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fa fa-map-pin mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Lokasi
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" v-if="isAdmin" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
          v-if="isAdmin"
        >
          User Management
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
          <li class="items-center" v-if="isAdmin">
            <router-link v-slot="{ href, navigate, isActive }" to="/home/users">
              <a
                :href="href"
                @click="handleNavigate(navigate)"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fa fa-user-circle mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                User
              </a>
            </router-link>
          </li>

          <!-- <li class="items-center" v-if="isAdmin || isSupervisor || isLeader"> -->
          <li class="items-center">
            <router-link
              v-slot="{ href, navigate, isActive }"
              to="/home/presences"
            >
              <a
                :href="href"
                @click="handleNavigate(navigate)"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fa fa-clipboard-list mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Absensi
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
          Media Management
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
          <li class="items-center">
            <router-link
              v-slot="{ href, navigate, isActive }"
              to="/home/handovers"
            >
              <a
                :href="href"
                @click="handleNavigate(navigate)"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fa fa-newspaper mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Berita Acara
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <router-link
              v-slot="{ href, navigate, isActive }"
              to="/home/galleries"
            >
              <a
                :href="href"
                @click="handleNavigate(navigate)"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fa fa-image mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Photo & Video
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
          v-if="false"
        >
          Activity Management
        </h6>
        <!-- Navigation -->
        <ul
          class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4"
          v-if="false"
        >
          <li class="items-center">
            <router-link
              to="/home/activity_reports"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="handleNavigate(navigate)"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-tools mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Laporan Kegiatan
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// eslint-disable-next-line no-unused-vars
import NotificationDropdown from "@/components/Dropdowns/NotificationDropdown.vue";
// eslint-disable-next-line no-unused-vars
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
// eslint-disable-next-line no-unused-vars
import logo from "@/assets/img/logo.png";

const store = useStore();
const router = useRouter();

const collapseShow = ref("hidden");
const level = ref(0);
const isAdmin = ref(false);
const isTopLevel = ref(false);
const isSupervisor = ref(false);
const isLeader = ref(false);
const target = ref(null); // Reference to the collapse element
const preventClickOutside = ref(false);

// eslint-disable-next-line no-unused-vars
const toggleCollapseShow = (classes) => {
  collapseShow.value = classes;
  preventClickOutside.value = true;
};

// Function to handle click outside of the target element
const handleClickOutside = (event) => {
  if (preventClickOutside.value) {
    preventClickOutside.value = false; // Reset the flag
    return; // Do not close the collapse
  }

  if (
    target.value &&
    !target.value.contains(event.target) &&
    collapseShow.value != "hidden"
  )
    collapseShow.value = "hidden";
};

// eslint-disable-next-line no-unused-vars
const handleNavigate = (navigate) => {
  collapseShow.value = "hidden";

  navigate();
};

// eslint-disable-next-line no-unused-vars
const goToHome = () => {
  if ([0, 1, 2, 3].includes(level.value)) router.push("/");
  else router.push("/home/activities");
};

onMounted(() => {
  level.value = store.state.auth.level;

  isAdmin.value = level.value == 0;
  isTopLevel.value = [1, 2, 3].includes(level.value);
  isSupervisor.value = level.value == 4;
  isLeader.value = level.value == 5;

  // Add the event listener when the component is mounted
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  // Clean up the event listener when the component is unmounted
  document.removeEventListener("click", handleClickOutside);
});
</script>
