export const API_URL = process.env.VUE_APP_API_URL;

export const ROLES = [
  {
    level: 1,
    key: "bkn",
    name: "Pimpinan BKN",
  },
  {
    level: 2,
    key: "inspecture",
    name: "Inspektorat",
  },
  {
    level: 3,
    key: "placeman",
    name: "Pejabat Pembuat Komitmen",
  },
  {
    level: 4,
    key: "supervisor",
    name: "Pengawas Lapangan",
  },
  {
    level: 5,
    key: "leader",
    name: "Koordinator Lapangan",
  },
];

export const ACTIVITY_TYPES = [
  {
    key: "receive",
    name: "Penerimaan Barang",
  },
  {
    key: "installation",
    name: "Installasi",
  },
  {
    key: "test",
    name: "Uji Fungsi",
  },
  {
    key: "daily use",
    name: "Pelaksanaan Harian",
  },
  {
    key: "take-down",
    name: "Pembongkaran",
  },
  {
    key: "rejection",
    name: "Kendala",
  },
];

export const ACTIVITY_STATUS = [
  {
    key: "draft",
    name: "Draft",
  },
  {
    key: "progress",
    name: "Progress",
  },
  {
    key: "accepted",
    name: "Disetujui",
  },
  {
    key: "rejected",
    name: "Ditolak",
  },
];
