<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span
          class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
        >
          <img
            alt="..."
            class="w-full rounded-full align-middle border-none shadow-lg"
            :src="image"
          />
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        {{ level }}
      </a>
      <div
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 cursor-pointer"
        @click="logout"
      >
        Logout
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

import image from "@/assets/img/avatar-placeholder.png";
import { mapUserRole } from "../../utils/mapUserRole";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
      level: "",
    };
  },
  mounted: function () {
    const localLevel = localStorage.getItem("level");
    this.level = localLevel == "0" ? "Admin" : mapUserRole(localLevel);
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      this.dropdownPopoverShow = !this.dropdownPopoverShow;
      if (this.dropdownPopoverShow) {
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    logout: async function () {
      try {
        await this.$store.dispatch("auth/logout");
        this.$router.push("/");
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    handleClickOutside(event) {
      const popoverDropdownRef = this.$refs.popoverDropdownRef;
      const btnDropdownRef = this.$refs.btnDropdownRef;

      // Check if click is outside both the popover and the button
      if (
        popoverDropdownRef &&
        !popoverDropdownRef.contains(event.target) &&
        btnDropdownRef &&
        !btnDropdownRef.contains(event.target)
      )
        this.dropdownPopoverShow = false;
    },
  },
};
</script>
