<template>
  <div
    class="fixed h-screen w-full flex justify-center items-center px-4"
    style="background: rgba(0, 0, 0, 0.3); z-index: 100"
  >
    <div
      class="w-full lg:w-1/2 p-4 bg-white rounded-md shadow-md"
      style="max-height: 90vh; overflow: hidden"
    >
      <!-- Set max height for modal and hide overflow -->
      <div class="w-full flex justify-between mb-2">
        <div class="w-full font-semibold">Form Kategori/Barang</div>
        <div class="w-2">
          <i class="fa fa-close cursor-pointer" @click="closeModal"></i>
        </div>
      </div>
      <hr />
      <div class="w-full flex justify-end mt-4">
        <button
          class="px-4 py-2 bg-emerald-500 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
          @click="addSelectedItems"
        >
          <i class="fa fa-plus mr-2"></i> Tambah yang Ditandai
        </button>
        <button
          class="px-4 py-2 bg-blueGray-600 hover:shadow-lg text-white font-semibold text-sm rounded"
          @click="toggleSelectAll"
        >
          {{ selectAll ? "Deselect All" : "Select All" }}
        </button>
      </div>
      <div class="w-full mt-4 p-6" style="max-height: 70vh; overflow-y: auto">
        <!-- Set max height for content and allow vertical scrolling -->
        <table class="w-full border-collapse border border-slate-500">
          <thead>
            <tr class="bg-indigo-500 text-white">
              <th class="p-2 border border-slate-300"></th>
              <th class="p-2 border border-slate-300">No.</th>
              <th class="p-2 border border-slate-300">Nama</th>
              <th class="p-2 border border-slate-300">Jumlah</th>
              <th class="p-2 border border-slate-300">Satuan</th>
              <th class="p-2 border border-slate-300">Tambahkan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item.id">
              <!-- Only show checkbox if item is not already in selectedItems -->
              <td align="center" class="p-2 border border-slate-300 text-sm">
                <input
                  v-if="!isItemSelected(item)"
                  type="checkbox"
                  v-model="selectedIds"
                  :value="item.id"
                />
              </td>
              <td align="center" class="p-2 border border-slate-300 text-sm">
                {{ index + 1 }}
              </td>
              <td align="center" class="p-2 border border-slate-300 text-sm">
                {{ item.name }}
              </td>
              <td align="center" class="p-2 border border-slate-300 text-sm">
                {{ item.qty }}
              </td>
              <td align="center" class="p-2 border border-slate-300 text-sm">
                {{ item.unit }}
              </td>
              <!-- Only show the "Add" button if the item is not already selected -->
              <td align="center" class="p-2 border border-slate-300 text-sm">
                <button
                  v-if="!isItemSelected(item)"
                  class="px-2 py-1 bg-emerald-500 text-white hover:shadow-lg font-semibold text-sm rounded"
                  @click="addItem(item)"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const items = computed(() => store.state.item.items);
const selectedItems = computed({
  get: () => store.state.activity.items,
  set: (val) => store.dispatch("activity/setItems", val),
});

// Track the selected item IDs for the checkboxes
const selectedIds = ref([]);

// Track the state of "Select All" button
const selectAll = ref(false);

// Function to check if an item is already in selectedItems
const isItemSelected = (item) =>
  selectedItems.value.some((i) => i.id === item.id);

// Function to add a single item
// eslint-disable-next-line no-unused-vars
const addItem = (item) => {
  if (!isItemSelected(item))
    selectedItems.value = [...selectedItems.value, item]; // Add item if not already in selectedItems list
};

// Function to add multiple selected items
// eslint-disable-next-line no-unused-vars
const addSelectedItems = () => {
  const selected = items.value.filter((item) =>
    selectedIds.value.includes(item.id)
  );

  selected.forEach((item) => {
    if (!isItemSelected(item))
      selectedItems.value = [...selectedItems.value, item]; // Add if not already in selectedItems
  });

  selectedIds.value = []; // Clear checkboxes after adding
  closeModal();
};

// Function to toggle "Select All" state
// eslint-disable-next-line no-unused-vars
const toggleSelectAll = () => {
  if (selectAll.value) selectedIds.value = [];
  else selectedIds.value = items.value.map((item) => item.id);

  selectAll.value = !selectAll.value;
};

// Function to close modal
// eslint-disable-next-line no-unused-vars
const closeModal = () => store.dispatch("modal/setModalItems", false);

onMounted(async () => {
  try {
    await store.dispatch("item/getData");
  } catch (error) {
    console.error("Get data failed:", error.message);
  }
});
</script>
