<template>
  <div>
    <modal-presence v-if="isShowModalPresence" />
    <modal-presence-image v-if="isShowModalPresenceImage" />
    <modal-items v-if="isShowModalItems" />
    <modal-approval v-if="isShowModalApproval" />
    <modal-reject v-if="isShowModalReject" />
    <modal-image v-if="isShowModalImage" />
    <modal-delete v-if="isShowDeleteEvidence" />
    <modal-loading v-if="isLoading" />
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <header-stats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24 h-full">
        <router-view />
        <br/>
        <!-- <footer-admin /> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
// eslint-disable-next-line no-unused-vars
import Sidebar from "@/components/Sidebar/Sidebar.vue";
// eslint-disable-next-line no-unused-vars
import HeaderStats from "@/components/Headers/HeaderStats.vue";
// eslint-disable-next-line no-unused-vars
// import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
// eslint-disable-next-line no-unused-vars
import ModalPresence from "@/components/Modals/ModalPresence.vue";
// eslint-disable-next-line no-unused-vars
import ModalPresenceImage from "@/components/Modals/ModalPresenceImage.vue";
// eslint-disable-next-line no-unused-vars
import ModalItems from "@/components/Modals/ModalItems.vue";
// eslint-disable-next-line no-unused-vars
import ModalApproval from "@/components/Modals/ModalApproval.vue";
// eslint-disable-next-line no-unused-vars
import ModalReject from "@/components/Modals/ModalReject.vue";
// eslint-disable-next-line no-unused-vars
import ModalImage from "@/components/Modals/ModalImage.vue";
// eslint-disable-next-line no-unused-vars
import ModalLoading from "@/components/Modals/ModalLoading.vue";
// eslint-disable-next-line no-unused-vars
import ModalDelete from "@/components/Modals/ModalDelete.vue";

const store = useStore();
// eslint-disable-next-line no-unused-vars
const isShowModalPresence = computed(
  () => store.state.modal.isShowModalPresence
);
// eslint-disable-next-line no-unused-vars
const isShowModalPresenceImage = computed(
  () => store.state.modal.isShowModalPresenceImage
);
// eslint-disable-next-line no-unused-vars
const isShowModalItems = computed(() => store.state.modal.isShowModalItems);
// eslint-disable-next-line no-unused-vars
const isShowModalApproval = computed(
  () => store.state.modal.isShowModalApproval
);
// eslint-disable-next-line no-unused-vars
const isShowModalReject = computed(() => store.state.modal.isShowModalReject);
// eslint-disable-next-line no-unused-vars
const isShowModalImage = computed(() => store.state.modal.isShowModalImage);
// eslint-disable-next-line no-unused-vars
const isShowDeleteEvidence = computed(() => store.state.modal.isShowDeleteEvidence);
// eslint-disable-next-line no-unused-vars
const isLoading = computed(() => store.state.modal.isLoading);
</script>
