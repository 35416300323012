const state = {
  isShowModalPresence: false,
  isShowModalPresenceImage: false,
  isShowModalItems: false,
  isShowModalApproval: false,
  isShowModalReject: false,
  isShowModalImage: false,
  isShowDeleteEvidence: false,
  isShowActivity: false,
  isShowPercentage: false,
  isLoading: false,
};

const mutations = {
  SET_MODAL_PRESENCE(state, value) {
    state.isShowModalPresence = value;
  },
  SET_MODAL_PRESENCE_IMAGE(state, value) {
    state.isShowModalPresenceImage = value;
  },
  SET_MODAL_ITEMS(state, value) {
    state.isShowModalItems = value;
  },
  SET_MODAL_APPROVAL(state, value) {
    state.isShowModalApproval = value;
  },
  SET_MODAL_REJECT(state, value) {
    state.isShowModalReject = value;
  },
  SET_MODAL_IMAGE(state, value) {
    state.isShowModalImage = value;
  },
  SET_DELETE_IMAGE(state, value) {
    state.isShowDeleteEvidence = value;
  },
  SET_MODAL_ACTIVITY(state, value) {
    state.isShowActivity = value;
  },
  SET_MODAL_PERCENTAGE(state, value) {
    state.isShowPercentage = value;
  },
  SET_LOADING(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  async setModalPresence({ commit }, value) {
    commit("SET_MODAL_PRESENCE", value);
  },
  async setModalPresenceImage({ commit }, value) {
    commit("SET_MODAL_PRESENCE_IMAGE", value);
  },
  async setModalItems({ commit }, value) {
    commit("SET_MODAL_ITEMS", value);
  },
  async setModalApproval({ commit }, value) {
    commit("SET_MODAL_APPROVAL", value);
  },
  async setModalReject({ commit }, value) {
    commit("SET_MODAL_REJECT", value);
  },
  async setModalImage({ commit }, value) {
    commit("SET_MODAL_IMAGE", value);
  },
  async setDeleteEvidence({ commit}, value) {
    commit("SET_DELETE_IMAGE", value);
  },
  async setModalActivity({ commit }, value) {
    commit("SET_MODAL_ACTIVITY", value);
  },
  async setModalPercentage({ commit }, value) {
    commit("SET_MODAL_PERCENTAGE", value);
  },
  async setLoading({ commit }, value) {
    commit("SET_LOADING", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
