<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 xl:mb-0 px-4">
        <activity-form />
      </div>
    </div>
  </div>
</template>
<script>
import ActivityForm from "@/components/Form/ActivityForm.vue";
export default {
  name: "dashboard-page",
  components: {
    ActivityForm
  },
};
</script>
