<template>
  <!-- Header -->
  <!-- background-color: #ed0b1c; #ce4e42; -->
  <div class="relative md:pt-32 pt-12 bg-red-500" style="padding-bottom: 100px">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap" v-if="isDashboard">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="Titik Lokasi Terdaftar"
              :statTitle="summaries.total_location"
              statDescripiron="Jumlah Lokasi aktif"
              statIconName="far fa-chart-bar"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="Pengawas lapangan"
              :statTitle="summaries.total_supervisor"
              statDescripiron="Pengawas Lapangan terdaftar"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="koordinasi lapangan"
              :statTitle="summaries.total_leader"
              statDescripiron="Koordinasi Lapangan terdaftar"
              statIconName="fas fa-users"
              statIconColor="bg-pink-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="Laporan kegiatan selesai"
              :statTitle="`${Math.ceil(summaries.percentage)}%`"
              statDescripiron="% Laporan Selesai"
              statIconName="fas fa-percent"
              statIconColor="bg-emerald-500"
            />
          </div>
        </div>
        <div
          class="w-full flex px-4"
          v-if="
            isGallery ||
            ((isHandover || isReport) && !(isSupervisor || isLeader)) ||
            (isActivity && !(isSupervisor || isLeader)) ||
            (isPresence && !(isSupervisor || isLeader))
          "
        >
          <div
            class="w-full flex flex-col md:flex-row items-center bg-white rounded-md p-4"
          >
            <div v-if="!(isLeader || isSupervisor)" class="mr-4 width-mobile-2">
              <div class="font-semibold">Pilih Kota</div>
              <div class="width-mobile">
                <select
                  class="rounded-md width-mobile"
                  v-model="cityId"
                  @change="(e) => onChangeCity(e)"
                  style="min-width: 120px"
                >
                  <option value=""></option>
                  <option
                    :value="city.id"
                    v-for="city in cities"
                    :key="city.id"
                  >
                    {{ city.name }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="!(isLeader || isSupervisor)" class="mr-4 width-mobile-2">
              <div class="font-semibold">Pilih Lokasi</div>
              <div class="width-mobile">
                <select
                  class="rounded-md width-mobile"
                  v-model="areaId"
                  @change="(e) => onChangeArea(e)"
                  style="min-width: 120px"
                >
                  <option value=""></option>
                  <option :value="area.id" v-for="area in areas" :key="area.id">
                    {{ area.name }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="isGallery" class="mr-4 width-mobile-2">
              <div class="font-semibold">Pilih Kegiatan</div>
              <div class="width-mobile">
                <select
                  class="rounded-md width-mobile"
                  @change="(e) => onChangeActivity(e)"
                  style="min-width: 120px"
                >
                  <option value=""></option>
                  <option
                    :value="activity.id"
                    v-for="activity in activities"
                    :key="activity.id"
                  >
                    {{ mapActivityType(activity.type) }} - {{ activity.name }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="isGallery" class="mr-4 width-mobile-2">
              <div class="font-semibold">Pilih Item</div>
              <div class="width-mobile">
                <select
                  class="rounded-md width-mobile"
                  style="min-width: 120px; max-width: 360px"
                  @change="(e) => onChangeItem(e)"
                >
                  <option value=""></option>
                  <option
                    v-for="detail in selectedActivity?.details"
                    :value="detail.id"
                    :key="detail.id"
                    style="max-width: 360px"
                  >
                    {{ detail.name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-if="!(isSupervisor || isLeader) || isGallery"
              class="mr-2 width-mobile-2"
            >
              <div class="font-semibold">&nbsp;</div>
              <button
                class="px-3 py-2 bg-emerald-600 hover:shadow-md text-white rounded-md width-mobile"
                @click="submit"
              >
                Submit
              </button>
            </div>
            <div v-if="isActivity">
              <div class="font-semibold">&nbsp;</div>
              <button
                class="px-3 py-2 bg-emerald-600 hover:shadow-md text-white rounded-md"
                @click="downloadExcel"
                v-if="isAdmin"
              >
                Download Excel
              </button>
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap"
          :class="{ 'mt-4': isShowProgress }"
          v-if="isShowProgress || (isActivity && (isLeader || isSupervisor))"
        >
          <div class="w-full flex flex-col lg:flex-row gap-6 px-4">
            <div class="w-full lg:w-1/2 flex bg-white p-4 rounded-md shadow-md">
              <table class="text-sm">
                <tr>
                  <td>Pengawas</td>
                  <td>
                    : {{ progressReport.supervisor }}
                    {{
                      progressReport.supervisor == "-"
                        ? ""
                        : "(" + progressReport.supervisor_phone + ")"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Korlap</td>
                  <td>
                    : {{ progressReport.leader }}
                    {{
                      progressReport.leader == "-"
                        ? ""
                        : "(" + progressReport.leader_phone + ")"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Teknisi</td>
                  <td>
                    : {{ progressReport.technician }}
                    {{
                      progressReport.technician == "-"
                        ? ""
                        : "(" + progressReport.technician_phone + ")"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Kota</td>
                  <td>: {{ progressReport.city }}</td>
                </tr>
                <tr>
                  <td>Lokasi</td>
                  <td>: {{ progressReport.location }}</td>
                </tr>
                <tr>
                  <td style="vertical-align: top">Alamat</td>
                  <td>: {{ progressReport.address }}</td>
                </tr>
              </table>
            </div>
            <div class="w-full lg:w-1/2 bg-white p-4 rounded-md shadow-md">
              <div
                class="overflow-hidden h-2 text-xs flex rounded bg-emerald-200"
                v-if="progressReport.current_state"
              >
                <div
                  :style="`width: ${progressReport.percentage}%`"
                  class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500"
                ></div>
              </div>
              <div class="text-sm mt-2" v-if="progressReport.current_state">
                Tahapan Sekarang:
                <!-- {{ progressReport.current_state?.name }} -->
                <span v-if="progressReport"
                  >{{ mapActivityType(progressReport.current_state?.type) }}
                </span>
              </div>
              <div v-else class="text-sm">Belum ada progress di area ini</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
// eslint-disable-next-line no-unused-vars
import CardStats from "@/components/Cards/CardStats.vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "@/utils/mapActivityType";

const store = useStore();

const route = useRoute();
// eslint-disable-next-line no-unused-vars
const isAdmin = computed(() => store.state.auth.level == 0);
const isSupervisor = computed(() => store.state.auth.level == 4);
const isLeader = computed(() => store.state.auth.level == 5);
// eslint-disable-next-line no-unused-vars
const cities = computed(() => store.state.city.cities);
// eslint-disable-next-line no-unused-vars
const areas = computed(() => store.state.area.areas);
// eslint-disable-next-line no-unused-vars
const activities = computed(() => store.state.activity.activities);
// eslint-disable-next-line no-unused-vars
const selectedActivity = computed(() => store.state.activity.selectedActivity);
// eslint-disable-next-line no-unused-vars
const progressReport = computed(() => store.state.activity.selected_report);
// eslint-disable-next-line no-unused-vars
const summaries = computed(() => store.state.activity.summaries);
const isShowProgress = ref(false);

const cityId = computed({
  get: () => store.state.filter.cityId,
  set: (val) => store.dispatch("filter/setCityId", val),
});

const areaId = computed({
  get: () => store.state.filter.areaId,
  set: (val) => store.dispatch("filter/setAreaId", val),
});

const activityId = computed({
  get: () => store.state.filter.activityId,
  set: (val) => store.dispatch("filter/setActivityId", val),
});

const isDashboard = ref(false);
const isActivity = ref(false);
const isPresence = ref(false);
const isHandover = ref(false);
const isGallery = ref(false);
const isReport = ref(false);

// eslint-disable-next-line no-unused-vars
const onChangeCity = async (e) => {
  cityId.value = e.target.value;
  store.dispatch("filter/setCityId", cityId.value);
  await store.dispatch("area/getData", cityId.value);
};

// eslint-disable-next-line no-unused-vars
const onChangeArea = async (e) => {
  areaId.value = e.target.value;
  store.dispatch("filter/setAreaId", areaId.value);

  if (isGallery.value) {
    await store.dispatch("activity/getData", {
      perPage: 1000,
      page: 1,
      areaId: areaId.value,
    });
  }
};

// eslint-disable-next-line no-unused-vars
const onChangeActivity = async (e) => {
  activityId.value = e.target.value;
  store.dispatch("filter/setActivityId", activityId.value);
  await store.dispatch("activity/getDetailData", activityId.value);
};

// eslint-disable-next-line no-unused-vars
const onChangeItem = async (e) => {
  store.dispatch("filter/setItemId", e.target.value);
};

// eslint-disable-next-line no-unused-vars
const submit = () => {
  if (isPresence.value) {
    return store.dispatch("presence/getData", {
      perPage: 10,
      page: 1,
      areaId: areaId.value,
    });
  }

  if (isHandover.value) {
    return store.dispatch("activity/getData", {
      perPage: 10,
      page: 1,
      areaId: areaId.value,
    });
  }

  if (isGallery.value) {
    return store.dispatch("evidence/getData", {
      perPage: 10,
      page: 1,
      activityId: activityId.value,
      itemId: store.state.filter.itemId,
    });
  }

  if (isReport.value) {
    return store.dispatch("activity/getData", {
      perPage: 10,
      page: 1,
      areaId: areaId.value,
    });
  }

  if (isActivity.value) {
    isShowProgress.value = true;
    store.dispatch("activity/getData", {
      perPage: 10,
      page: 1,
      areaId: areaId.value,
    });
    return store.dispatch("activity/getProgressReport", areaId.value);
  }
};

// eslint-disable-next-line no-unused-vars
const downloadExcel = () => {
  if (isActivity.value) store.dispatch("activity/downloadExcel", areaId.value);
};

// Watch for route changes
watch(
  () => route.path,
  async (newPath) => {
    if (store.state.filter.isClearFilter) {
      store.dispatch("filter/setCityId", null);
      store.dispatch("filter/setAreaId", null);
      store.dispatch("filter/setActivityId", null);
      store.dispatch("filter/setItemId", null);
      store.dispatch("activity/clearActivities");
      isShowProgress.value = false;
    } else {
      store.dispatch("activity/getProgressReport", areaId.value);
      store.dispatch("filter/clearFilter");
      isShowProgress.value = true;
    }

    isDashboard.value = newPath === "/home/dashboard";
    isActivity.value = newPath === "/home/activities";
    isPresence.value = newPath === "/home/presences";
    isHandover.value = newPath === "/home/handovers";
    isGallery.value = newPath === "/home/galleries";
    isReport.value = newPath === "/home/activity_reports";

    if (isGallery.value && (isSupervisor.value || isLeader.value)) {
      store.dispatch("activity/getData", {
        perPage: 1000,
        areaId: areaId.value,
      });
    }

    if (newPath === "/home/dashboard") store.dispatch("activity/getSummaries");
  }
);

onMounted(async () => {
  isDashboard.value = route.path === "/home/dashboard";
  isActivity.value = route.path === "/home/activities";
  isPresence.value = route.path === "/home/presences";
  isHandover.value = route.path === "/home/handovers";
  isGallery.value = route.path === "/home/galleries";
  isReport.value = route.path === "/home/activity_reports";

  if (isDashboard.value) await store.dispatch("activity/getSummaries");

  if (!(isLeader.value || isSupervisor.value))
    await store.dispatch("city/getData");

  if (isLeader.value || isSupervisor.value)
    await store.dispatch("activity/getData", { perPage: 1000 });

  if (isActivity.value && (isLeader.value || isSupervisor.value))
    await store.dispatch("activity/getProgressReport");
});
</script>
