<template>
  <div class="flex mt-4">
    <div class="w-full px-4">
      <card-users />
    </div>
  </div>
</template>
<script>
import CardUsers from "@/components/Dashboard/CardUsers.vue";

export default {
  components: {
    CardUsers
  },
};
</script>
