<template>
  <div
    class="absolute min-h-screen w-full flex justify-center items-center"
    style="background: rgba(0, 0, 0, 0.3); z-index: 100"
  >
    <div class="w-1/2 p-4 bg-white rounded-md shadow-md">
      <div class="w-full flex justify-between mb-2">
        <div class="w-full font-semibold">Setujui Kegiatan</div>
        <div class="w-2">
          <i class="fa fa-close cursor-pointer" @click="closeModal"></i>
        </div>
      </div>
      <hr />
      <div class="w-full mt-4 p-6">
        <div>
          Anda yakin ingin menolak kegiatan?
          {{ mapActivityType(activity.type) }}
        </div>
        <div class="mt-2">
          <span>Catatan</span>
          <textarea
            type="text"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            rows="4"
            placeholder="Masukkan catatan disini"
            v-model="note"
          ></textarea>
        </div>
      </div>
      <div class="w-full flex justify-end mt-2">
        <button
          class="px-4 py-2 bg-red-500 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
          @click="closeModal"
        >
          <i class="fa fa-close mr-2"></i> Batalkan
        </button>
        <button
          class="px-4 py-2 bg-emerald-500 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
          @click="submit"
        >
          <i class="fa fa-check mr-2"></i> Yakin
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "@/utils/mapActivityType";

const store = useStore();
const activity = computed(() => store.state.activity.selectedActivity);
const note = ref("");

const closeModal = () => store.dispatch("modal/setModalReject", false);

// eslint-disable-next-line no-unused-vars
const submit = async () => {
  const payload = {
    id: activity.value.id,
    name: store.state.auth.name,
    phone: store.state.auth.phone,
    note: note.value,
  };

  await store.dispatch("activity/reject", payload);

  closeModal();
};
</script>
