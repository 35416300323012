import checkToken from "../../utils/checkToken";
import { API_URL } from "../constant";

const state = {
  token: localStorage.getItem("token") || "",
  areas: [],
  selectedArea: null,
};

const mutations = {
  SET_AREAS(state, areas) {
    state.areas = areas;
    // state.selectedArea = null;
  },
  SET_SELECTED_AREA(state, selectedArea) {
    state.selectedArea = selectedArea;
  },
};

const actions = {
  async getData({ commit }, cityId = null) {
    try {
      const url =
        cityId == null
          ? `${API_URL}/areas`
          : `${API_URL}/areas?city_id=${cityId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) commit("SET_AREAS", data.data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      checkToken(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
