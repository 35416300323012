import { API_URL } from "../constant";

const state = {
  token: localStorage.getItem("token") || "",
  cities: [],
  selectedCity: null,
};

const mutations = {
  SET_CITIES(state, cities) {
    state.cities = cities;
    // state.selectedCity = null;
  },
  SET_SELECTED_CITY(state, selectedCity) {
    state.selectedCity = selectedCity;
  },
};

const actions = {
  async getData({ commit }) {
    try {
      const response = await fetch(`${API_URL}/cities`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) commit("SET_CITIES", data.data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
