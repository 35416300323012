import checkToken from "../../utils/checkToken";
import { API_URL } from "../constant";

const state = {
  token: localStorage.getItem("token") || "",
  items: [],
  selectedItem: null,
  selectedItemCategory: 1,
};

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
    // state.selectedItem = null;
  },
  SET_SELECTED_ITEM(state, selectedItem) {
    state.selectedItem = selectedItem;
  },
  SET_SELECTED_ITEM_CATEGORY(state, selectedItemCategory) {
    state.selectedItemCategory = selectedItemCategory;
  },
};

const actions = {
  async getData({ commit }) {
    try {
      const response = await fetch(
        `${API_URL}/items?type=${state.selectedItemCategory}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) commit("SET_ITEMS", data.data);
      else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      checkToken(error);
      throw error;
    }
  },
  setCategory({ commit }, category) {
    commit("SET_SELECTED_ITEM_CATEGORY", category);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
