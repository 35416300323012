<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700 uppercase">
            List Berita Acara
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Tanggal
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Jam
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Lokasi
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Kegiatan
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Keterangan
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              File
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="activity in activities" :key="activity.id">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ dateFormat(activity.created_at) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ timeFormat(activity.created_at) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 font-semibold"
            >
              {{ activity.area.name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ mapActivityType(activity.type) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ activity.name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              <button
                class="px-3 py-2 bg-emerald-500 hover:shadow-md text-white font-semibold"
                v-if="activity.bap_file"
                @click="downloadBAP(activity)"
              >
                Download BA
              </button>
              <span v-else>BA belum diupload</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination Component -->
    <div class="flex justify-between items-center p-4">
      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === 1"
        @click="changePage(page - 1)"
      >
        Previous
      </button>

      <span class="text-sm text-gray-700">
        Page {{ page }} of {{ totalPages }}
      </span>

      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === totalPages"
        @click="changePage(page + 1)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { dateFormat } from "@/utils/dateFormat";
// eslint-disable-next-line no-unused-vars
import { timeFormat } from "../../utils/timeFormat";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "@/utils/mapActivityType";

const store = useStore();
// eslint-disable-next-line no-unused-vars
const activities = computed(() => store.state.activity.activities);
const isSupervisor = computed(() => store.state.auth.level == 4);
const isLeader = computed(() => store.state.auth.level == 5);
const areaId = computed(() => store.state.filter.areaId);
// eslint-disable-next-line no-unused-vars
const totalPages = computed(() => store.state.activity.totalPages);
const page = ref(1);

// eslint-disable-next-line no-unused-vars
const downloadBAP = async (activity) =>
  await store.dispatch("activity/downloadBAP", {
    id: activity.id,
    filename: activity.bap_file,
    type: mapActivityType(activity.type),
  });

// eslint-disable-next-line no-unused-vars
const changePage = (val) => {
  page.value = val;
  store.dispatch("activity/getData", {
    perPage: 10,
    page: val,
    areaId: areaId.value,
  });
};

onMounted(async () => {
  try {
    if (isSupervisor.value || isLeader.value)
      await store.dispatch("activity/getData", { perPage: 10, page: 1 });
  } catch (error) {
    console.error("Get data failed:", error.message);
  }
});
</script>
