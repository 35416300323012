<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div
        class="w-full px-4"
        :class="{
          'xl:w-8/12 mb-12 xl:mb-0': !isShowActivity && !isShowPercentage,
        }"
        v-if="!isShowPercentage"
      >
        <card-report-activities />
      </div>
      <div
        class="w-full px-4"
        :class="{
          'xl:w-4/12': !isShowActivity && !isShowPercentage,
        }"
        v-if="!isShowActivity"
      >
        <card-percentage-locations />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import CardReportActivities from "@/components/Dashboard/CardReportActivities.vue";
// eslint-disable-next-line no-unused-vars
import CardPercentageLocations from "@/components/Dashboard/CardPercentageLocations.vue";

const store = useStore();

// eslint-disable-next-line no-unused-vars
const isShowActivity = computed(() => store.state.modal.isShowActivity);
// eslint-disable-next-line no-unused-vars
const isShowPercentage = computed(() => store.state.modal.isShowPercentage);
</script>
