const checkToken = (err) => {
  // Check if the error response is HTML
  const response = err.response;

  if (
    response &&
    response.status === 500 &&
    response.data.includes("RouteNotFoundException")
  ) {
    localStorage.removeItem("token");

    window.location.href = "/";
  }
};

export default checkToken;
