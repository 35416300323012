<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700 uppercase">
            Laporan Kegiatan
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="addActivity"
            v-if="isLeader"
          >
            Tambah Kegiatan
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Lokasi
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Pengawas
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Korlap
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Kegiatan
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Keterangan
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Detail
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in activities" :key="item.id">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 font-semibold"
            >
              {{ item.area.name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              <div class="flex items-center justify-center">
                <i
                  class="fas fa-circle text-emerald-500 mr-2"
                  v-if="item.is_supervisor_presence"
                ></i>
                <i class="fas fa-circle text-red-500 mr-2" v-else></i>
                <!-- <span class="hidden lg:block">{{
                  item.is_supervisor_presence ? item.supervisor.name : "-"
                }}</span> -->
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 flex items-center justify-center"
            >
              <i
                class="fas fa-circle text-emerald-500 mr-2"
                v-if="item.is_leader_presence"
              ></i>
              <i class="fas fa-circle text-red-500 mr-2" v-else></i>
              <!-- <span class="hidden lg:block">{{ item.is_leader_presence ? item.leader.name : "-" }}</span> -->
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ mapActivityType(item.type) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ mapActivityStatus(item.status) }}
            </td>
            <td align="center">
              <button
                class="font-semibold text-sm"
                @click="activityDetail(item)"
              >
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination Component -->
    <div class="flex justify-between items-center p-4">
      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === 1"
        @click="changePage(page - 1)"
      >
        Previous
      </button>

      <span class="text-sm text-gray-700">
        Page {{ page }} of {{ totalPages }}
      </span>

      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === totalPages"
        @click="changePage(page + 1)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "@/utils/mapActivityType";
// eslint-disable-next-line no-unused-vars
import { mapActivityStatus } from "../../utils/mapActivityStatus";

const router = useRouter();
const store = useStore();
// eslint-disable-next-line no-unused-vars
const activities = computed(() => store.state.activity.activities);
const isSupervisor = computed(() => store.state.auth.level == 4);
const isLeader = computed(() => store.state.auth.level == 5);
// eslint-disable-next-line no-unused-vars
const isTakedDown = computed(() => store.state.activity.lastTypeIndex == 5);
const areaId = computed(() => store.state.filter.areaId);
// eslint-disable-next-line no-unused-vars
const totalPages = computed(() => store.state.activity.totalPages);
const page = ref(1);

// eslint-disable-next-line no-unused-vars
const addActivity = () => router.push("/home/activities/add");

// eslint-disable-next-line no-unused-vars
const activityDetail = (activity) =>
  router.push(`/home/activities/${activity.id}/detail`);

// eslint-disable-next-line no-unused-vars
const changePage = (val) => {
  page.value = val;
  store.dispatch("activity/getData", {
    perPage: 10,
    page: val,
    areaId: areaId.value,
  });
};

onMounted(async () => {
  try {
    if (isLeader.value || isSupervisor.value) {
      await store.dispatch("activity/getData", {
        perPage: 10,
        page: 1,
        areaId: areaId.value,
      });
    }
  } catch (error) {
    console.error("Get data failed:", error.message);
  }
});
</script>
