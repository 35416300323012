<template>
  <div
    class="absolute min-h-screen w-full flex justify-center items-center"
    style="background: rgba(0, 0, 0, 0.3); z-index: 100"
  >
    <div class="w-1/2 p-4 bg-white rounded-md shadow-md">
      <div class="w-full flex justify-between mb-2">
        <div class="w-full font-semibold">Hapus Media</div>
        <div class="w-2">
          <i class="fa fa-close cursor-pointer" @click="closeModal"></i>
        </div>
      </div>
      <hr />
      <div class="w-full mt-4 p-6">
        <div>
          Anda yakin ingin menghapus media?
        </div>
      </div>
      <div class="w-full flex justify-end mt-2">
        <button
          class="px-4 py-2 bg-red-500 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
          @click="closeModal"
        >
          <i class="fa fa-close mr-2"></i> Batalkan
        </button>
        <button
          class="px-4 py-2 bg-emerald-500 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
          @click="submit"
        >
          <i class="fa fa-check mr-2"></i> Yakin
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const evidence = computed(() => store.state.evidence.selectedEvidence);

const closeModal = () => store.dispatch("modal/setDeleteEvidence", false);

// eslint-disable-next-line no-unused-vars
const submit = async () => {
  await store.dispatch("modal/setLoading", true);
  await store.dispatch("evidence/delete", evidence.value.id);

  closeModal();
};
</script>
